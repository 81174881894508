<!-- 患者の心電図検査開始ダイアログ -->
<template>
  <div>
    <v-dialog
      v-model="isDisplay"
      @input="closeDialog"
      max-width="1200"
    >
        <patient-register-form v-if="progress=='editing'"
          :item="currentItem"
          :beforeAnalysisOpinionType="beforeAnalysisOpinionType"
          :isContractedAnalysisOption="isContractedAnalysisOption"
          :mode="mode"
          :status="status"
          @close="closeDialog"
          @confirm="enterConfirm"
        />
        <patient-register-confirm v-else-if="progress=='confirm'"
          :item="currentItem"
          :beforeAnalysisOpinionType="beforeAnalysisOpinionType"
          :isContractedAnalysisOption="isContractedAnalysisOption"
          :mode="mode"
          :status="status"
          @reenter="reenter"
          @complete="enterComplete"
          @close="closeDialog"
        />
        <patient-register-complete v-else-if="progress=='complete'"
          :item="currentItem"
          @close="closeDialog"
        />
    </v-dialog>
  </div>
</template>

<script>

import PatientRegisterForm from '@/components/PatientRegisterForm.vue';
import PatientRegisterConfirm from '@/components/PatientRegisterConfirm.vue';
import PatientRegisterComplete from '@/components/PatientRegisterComplete.vue';

export default {
  name: 'PatientDialog',
  components: {
    PatientRegisterForm,
    PatientRegisterConfirm,
    PatientRegisterComplete
  },
  props: {
    value: Boolean,
    item: Object,
    mode: {
      type: String,
      default: 'none'
    },
    status: Number,
  },
  data() {
    return {
      progress: 'editing',
      currentItem: null,
      datePicker: false,
      isDisplay: true,
      beforeAnalysisOpinionType: null,
      isContractedAnalysisOption: null,
      isUpdatedCustomerOrgsDict: false,
    }
  },
  methods: {
    reenter() {
      this.progress = 'editing';
    },
    enterConfirm(inputedItem) {
      this.currentItem = inputedItem;
      this.progress = 'confirm';
    },
    enterComplete(confirmedItem) {
      this.currentItem = confirmedItem;
      this.progress = 'complete';
    },
    closeDialog(isUpdateFinished = false) {
      console.log('PatientDialog closeDialog');
      this.isDisplay = false;
      this.currentItem = null;
      this.reenter();
      this.$emit('closed', isUpdateFinished);
    },
    getAnalysisOptionInfo(orgData) {
      // オプション解析契約状況
      this.isContractedAnalysisOption = ('is_contracted_analysis_option' in orgData && orgData.is_contracted_analysis_option == true) ? true : false;
    },
  },
  mounted() {
    console.log('PatientDialog mounted');
    this.currentItem = JSON.parse(JSON.stringify(this.item));
    // 変更前の解析オプション
    this.beforeAnalysisOpinionType = ('analysis_opinion_type' in this.currentItem && this.currentItem.analysis_opinion_type !== null) ? parseInt(this.currentItem.analysis_opinion_type) : 0;
    if (this.$store.state.user.org_id in this.customerOrgsDict) {
      // 組織情報取得済み
      this.isUpdatedCustomerOrgsDict = true;
      this.getAnalysisOptionInfo(this.customerOrgsDict[this.$store.state.user.org_id]);
    } 
  },
  watch: {
    customerOrgsDict(value) {
      if (!this.isUpdatedCustomerOrgsDict) {
        // 最初の一回だけ
        this.isUpdatedCustomerOrgsDict = true;
        this.getAnalysisOptionInfo(value[this.$store.state.user.org_id]);
      }
    },
  },
  computed: {
    customerOrgsDict() {
      return this.$store.state.customerOrgsDict;
    },
  }
}
</script>