<template>
  <div class="pa-4">
    <v-container fluid>
      <h2 text color="black" class="headline">心電図解析状況一覧</h2>
      <v-row dense>
        <v-col cols="12" class="align-self-center">
          <v-card class="v-card--flat">
            <v-toolbar
              id="search-field"
              color="primary"
              flat
              dark
              height="auto"
              min-width="100%"
              class="top-rounded"
              :collapse="isCollapse"
            >
              <v-row class="align-center" style="width: 100%;">

                <v-col cols="3">
                  <v-text-field
                    dense
                    type="date"
                    class="align-center"
                    v-model="searchAttachedStart"
                    label="検査開始日(抽出開始日)"
                    hide-details
                    clearable
                    :disabled="isUnusedOnly"
                  />
                </v-col>

                <span>&nbsp;〜&nbsp;</span>

                <v-col cols="3">
                  <v-text-field
                    dense
                    type="date"
                    class="align-center"
                    v-model="searchAttachedEnd"
                    label="検査開始日(抽出終了日)"
                    hide-details
                    clearable
                    :disabled="isUnusedOnly"
                  />
                </v-col>

                <v-col cols="12" sm="3" style="display: flex; justify-content: flex-end; align-items: center;">
                  <div class="text-caption mr-2">拡張検索欄の表示</div>
                    <v-btn
                    dense
                            hide-details
                      icon
                      @click="toggleCollapse()"
                    >
                      <v-icon class="mx-3" v-if="!isCollapse">mdi-toggle-switch-outline</v-icon>
                      <v-icon class="mx-3" v-else>mdi-toggle-switch-off-outline</v-icon>
                    </v-btn>
                </v-col>


              </v-row>

              <v-row class="mt-1" v-if="!isCollapse">
                <v-col cols="12"><h4>拡張検索欄</h4></v-col>
              </v-row>

              <v-row class="align-center mt-0" v-if="!isCollapse"  style="width: 100%;">
                <v-col cols="4">
                  <v-text-field
                    dense
                    prepend-inner-icon="search"
                    label="デバイスID"
                    outlined
                    clearable
                    hide-details
                    v-model="searchDeviceId"
                    @blur="subscribeItems"
                    @keyup.enter="subscribeItems"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-select
                    dense
                    hide-details
                    v-model="searchSelectedStatus"
                    :items="selectStatusList"
                    label="デバイス状態"
                    no-data-text="選択対象がありません。"
                    @input="subscribeItems"
                  />
                </v-col>
                <v-col cols="4">
                  <v-select
                    dense
                    hide-details
                    v-model="selectedCustomerStaffId"
                    :items="customerStaffSelectItems"
                    label="担当医"
                    no-data-text="選択対象がありません。"
                    @input="subscribeItems"
                  />
                  <!-- <v-text-field
                    dense
                    prepend-inner-icon="search"
                    label="解析ID"
                    outlined
                    clearable
                    hide-details
                    v-model="searchAnalysisId"
                    @blur="subscribeItems"
                    @keyup.enter="subscribeItems"
                  ></v-text-field> -->
                </v-col>
              </v-row>

              <v-row class="align-center" v-if="!isCollapse"  style="width: 100%;">
                <v-col cols="4">
                  <v-text-field
                    dense
                    label="検査番号"
                    outlined
                    clearable
                    hide-details
                    v-model="searchExamNumber"
                    prepend-inner-icon="search"
                    @blur="subscribeItems"
                    @keyup.enter="subscribeItems"
                  ></v-text-field>
                  <!-- <v-select
                    dense
                    hide-details
                    v-model="selectedAnalysisOption"
                    :items="selectAnalysisOptionList"
                    label="解析オプション"
                    no-data-text="選択対象がありません。"
                    @input="subscribeItems"
                  /> -->
                </v-col>
                <v-col cols="4" class="patient-initial align-center">
                  <div class="mr-6 v-input v-input--selection-controls v-input--radio-group">
                    <legend>患者イニシャル</legend>
                  </div>
                  <v-text-field
                    dense
                    v-model="searchPatientInitialFirst"
                    maxlength="1"
                    class="one-digit-charactor"
                    @input="subscribeItems"
                    hide-details
                  />
                  <v-text-field
                    dense
                    v-model="searchPatientInitialSecond"
                    maxlength="1"
                    class="one-digit-charactor"
                    ref="patientInitialSecondTextField"
                    @input="subscribeItems"
                    hide-details
                  />
                </v-col>
                <v-col cols="4">
                  <v-select
                    dense
                    hide-details
                    v-model="searchSelectedSex"
                    :items="selectSexList"
                    label="性別"
                    no-data-text="選択対象がありません。"
                    @input="subscribeItems"
                  />
                </v-col>
              </v-row>

              <v-row class="align-center" v-if="!isCollapse"  style="width: 100%;">
                <v-col cols="4" class="patient-age align-center">
                  <div class="mr-6 v-input v-input--selection-controls v-input--radio-group">
                    <legend>年齢</legend>
                  </div>
                  <v-text-field
                    dense
                    v-model="searchPatientAgeStart"
                    type="number"
                    min="0"
                    max="200"
                    class="three-digit-charactor first-char"
                    @input="subscribeItems"
                    hide-details
                  />
                  <span class="mr-4">&nbsp;〜&nbsp;</span>
                  <v-text-field
                    dense
                    v-model="searchPatientAgeEnd"
                    type="number"
                    min="0"
                    max="200"
                    class="three-digit-charactor"
                    ref="patientInitialSecondTextField"
                    @input="subscribeItems"
                    hide-details
                  />
                </v-col>

              </v-row>
            </v-toolbar>
          </v-card>
        </v-col>
      </v-row>

      <v-row dense v-if="isOverQueryLimit">
        <v-col cols="12">
          <v-alert
            class="ma-0"
            dense
            dismissible
            outlined
            v-model="isOverQueryLimit"
            type="warning"
            prominent
          >
            <span v-for="(msg, index) in msgsOverQueryLimit" :key="index">
            {{ msg }}<br />
            </span>
          </v-alert>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">

          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page.sync=selectedLinesPerPage
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            @update:sort-by="updateSortBy"
            @update:sort-desc="updateSortDesc"
            hide-default-footer
            fixed-header
            height="700"
          >
            <template v-slot:top="{ pagination, options, updateOptions }">
              <v-data-footer
                :pagination="pagination"
                :options="options"
                @update:options="updateOptions"
                items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                :items-per-page-options="linesPerPageList"
                class="border-less"
              />
            </template>
            <template v-slot:[`item.action_result`]="{ item }">
              <v-tooltip
                v-if="item.analyzed != null && item.analyzed.trim() != ''"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="openMyHolter(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon class="mx-3">remove_red_eye</v-icon>
                  </v-btn>
                </template>
                <span>解析結果画面表示</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.center_id`]="{ item }">
              {{centers[item.center_id] ? centers[item.center_id].name: ''}}
            </template>

            <template v-slot:[`item.order_org_id`]="{ item }">
              {{customerOrgs[item.order_org_id] ? customerOrgs[item.order_org_id].name: ''}}
            </template>


            <template v-slot:[`item.role`]="{ item }">
              {{roleNames[item.role]}}
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <device-status-chip :item="item" />
            </template>

            <template v-slot:[`item.analysis_opinion_type`]="{ item }">
              {{ displayAnalysisOpinionType(item) }}
            </template>

            <template v-slot:[`item.update_patient`]="{ item }">
              <v-tooltip
                v-if="item.status >= 4"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="updatePatientForm(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon class="mx-3">edit</v-icon>
                  </v-btn>
                </template>
                <span>患者情報更新</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.cancel_patient`]="{ item }">
              <v-tooltip
                v-if="item.status == 4 && item.isPossibleCancel"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="cancelPatientForm(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon class="mx-3">mdi-arrow-u-left-top</v-icon>
                  </v-btn>
                </template>
                <span>検査開始登録キャンセル</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.customer_staff_Names`]="{ item }">
              <div style="white-space: pre;">{{ item.customer_staff_Names }}</div>
            </template>
            <!-- 心電計型式 -->
            <template v-slot:item.ecg_type="{ item }">
              {{ ecgType2Text(item) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <patient-dialog
      v-if="patientMode != 'none'"
      v-model="patientDialogModel"
      @closed="onPatientDialogClosed"
      :mode="patientMode"
      :item="patientItem"
      :status="currentStatus"
    />
  </div>
</template>

<script>
import dateformat from 'dateformat';
import PatientDialog from '@/components/PatientDialog.vue';
import DeviceStatusChip from '@/components/DeviceStatusChip.vue';
import AnalysisOpinionType from '@/mixins/AnalysisOpinionType.js';

let unsubscribeItems = null;
let unsubscribeCustomerStaffs = null;

let itemsRef = null;

const ts2str = (ts) => {
  return ts ? dateformat(new Date(ts.seconds * 1000), 'yyyy/mm/dd HH:MM:ss'): '';
}

export default {
  name: 'Analyses',
  components: {
    PatientDialog,
    DeviceStatusChip,
  },
  mixins:[
    AnalysisOpinionType,
  ],
  data() {
    return {
      itemsName: 'devices',
      typeRadioGroupModel: null,
      inventoryDialogModel: false,
      patientDialogModel: false,
      headers: [
        {
          text: '操作',
          align: 'left',
          sortable: false,
          value: 'action',
          width: '64px',
        },
        {
          text: 'デバイスID',
          align: 'left',
          sortable: true,
          value: 'device_id',
          width: '120px',
        },
        {
          text: '状態',
          align: 'left',
          sortable: true,
          value: 'status',
          width: '108px',
        },
        {
          text: '販売組織',
          align: 'left',
          sortable: true,
          value: 'center_id',
          width: '120px',
        },
        {
          text: '医療機関',
          align: 'left',
          sortable: true,
          value: 'order_org_id',
          width: '108px',
        },
        // {
        //   text: '製造日時',
        //   align: 'left',
        //   sortable: true,
        //   value: 'produced',
        //   width: '100px',
        // },
        {
          text: '入荷日時',
          align: 'left',
          sortable: true,
          value: 'arrived',
          width: '100px',
        },
        // {
        //   text: '発送日時',
        //   align: 'left',
        //   sortable: true,
        //   value: 'shipped',
        //   width: '100px',
        // },
        {
          text: '登録日時',
          align: 'left',
          sortable: true,
          value: 'received2',
          width: '100px',
        },
        {
          text: '患者装着日時',
          align: 'left',
          sortable: true,
          value: 'patient_attached',
          width: '100px',
        },
        {
          text: '心電計受付日時',
          align: 'left',
          sortable: true,
          value: 'device_collected',
          width: '100px',
        },
        {
          text: '行動記録用紙受付日時',
          align: 'left',
          sortable: true,
          value: 'log_collected',
          width: '100px',
        },
        {
          text: '心電図アップロード日時',
          align: 'left',
          sortable: true,
          value: 'device_uploaded',
          width: '100px',
        },
        {
          text: '行動記録用紙アップロード日時',
          align: 'left',
          sortable: true,
          value: 'log_uploaded',
          width: '100px',
        },
        {
          text: '廃棄日時',
          align: 'left',
          sortable: true,
          value: 'disposed',
          width: '100px',
        },
      ],
      headersCustomer: [
        {
          text: '解析結果',
          align: 'left',
          sortable: false,
          value: 'action_result',
          width: '70px',
        },
        {
          text: 'デバイスID',
          align: 'left',
          sortable: true,
          value: 'device_id',
          width: '110px',
        },
        {
          text: '型式',
          align: 'left',
          sortable: true,
          value: 'ecg_type',
          width: '80px',
        },
        {
          text: '状態',
          align: 'left',
          sortable: true,
          value: 'status',
          width: '60px',
        },
        {
          text: '担当医',
          align: 'left',
          sortable: true,
          value: 'customer_staff_Names',
          width: '120px',
        },
        {
          text: '検査開始日時',
          align: 'left',
          sortable: true,
          value: 'patient_attached',
          width: '170px'
        },
        {
          text: '検査番号',
          align: 'left',
          sortable: true,
          value: 'exam_number',
          width: '100px',
        },
        {
          text: 'イニシャル',
          align: 'left',
          sortable: true,
          value: 'patient_initial',
          width: '70px',
        },
        {
          text: '年齢',
          align: 'left',
          sortable: true,
          value: 'age',
          width: '80px',
        },
        {
          text: '性別',
          align: 'left',
          sortable: true,
          value: 'sex',
          width: '80px',
        },
        // {
        //   text: '解析/所見',
        //   align: 'left',
        //   sortable: true,
        //   value: 'analysis_opinion_type',
        //   width: '110px',
        // },
        // {
        //   text: '解析ID',
        //   align: 'left',
        //   sortable: true,
        //   value: 'analysis_id',
        //   width: '90px',
        // },
        {
          text: '患者情報更新',
          align: 'left',
          sortable: true,
          value: 'update_patient',
          width: '70px',
        },
        {
          text: '検査開始登録キャンセル',
          align: 'left',
          sortable: true,
          value: 'cancel_patient',
          width: '100px',
        }
      ],
      // 以下のヘッダーは My holter2本番環境で「PDF1ページ目作成・表示機能」が実装されたらいらない
      headersCustomerTmp: [
        {
          text: '解析結果',
          align: 'left',
          sortable: false,
          value: 'action_result',
          width: '70px',
        },
        {
          text: 'デバイスID',
          align: 'left',
          sortable: true,
          value: 'device_id',
          width: '110px',
        },
        {
          text: '型式',
          align: 'left',
          sortable: true,
          value: 'ecg_type',
          width: '80px',
        },
        {
          text: '状態',
          align: 'left',
          sortable: true,
          value: 'status',
          width: '60px',
        },
        {
          text: '担当医',
          align: 'left',
          sortable: true,
          value: 'customer_staff_Names',
          width: '120px',
        },
        {
          text: '検査開始日時',
          align: 'left',
          sortable: true,
          value: 'patient_attached',
          width: '170px'
        },
        {
          text: '検査番号',
          align: 'left',
          sortable: true,
          value: 'exam_number',
          width: '100px',
        },
        {
          text: 'イニシャル',
          align: 'left',
          sortable: true,
          value: 'patient_initial',
          width: '70px',
        },
        {
          text: '年齢',
          align: 'left',
          sortable: true,
          value: 'age',
          width: '80px',
        },
        {
          text: '性別',
          align: 'left',
          sortable: true,
          value: 'sex',
          width: '80px',
        },
        // {
        //   text: '解析ID',
        //   align: 'left',
        //   sortable: true,
        //   value: 'analysis_id',
        //   width: '90px',
        // },
        {
          text: '患者情報更新',
          align: 'left',
          sortable: true,
          value: 'update_patient',
          width: '70px',
        },
        {
          text: '検査開始登録キャンセル',
          align: 'left',
          sortable: true,
          value: 'cancel_patient',
          width: '100px',
        }
      ],
      uniqueIdRules: [],
      remarksRules: [],
      items: [],
      currentItem: {},
      patientMode: 'none',
      recordDialogTitle: '登録',
      configRobots: {},
      modelNumbers: [],
      /**
       * 検索フィールド
       * デバイスIDと検査番号のフォームを分ける処理を入れることで、このプロパティは不要になるが、
       * 消していいのかは不明
       */
      searchKeyword: this.$route.params ?
        this.$route.params.device_id ?
          this.$route.params.device_id
          : ''
        : '',

      accessTypeModel: 'all',
      searchSelectedField: this.$route.params ?
        this.$route.params.device_id ?
          'デバイスID'
          : '検査番号'
        : '検査番号',
      searchFields: ['検査番号', 'デバイスID'],
      selectedCustomerStaffId: null,
      customerStaffSelectItems: [],
      searchSelectedStatus: null,
      selectStatusList: [
        { text: '全て', value: null },
        { text: this.$store.state.deviceStatusNames[4], value: 4 },
        { text: this.$store.state.deviceStatusNames[5], value: 5 },
        { text: this.$store.state.deviceStatusNames[7], value: 7 },
      ],
      searchAttachedStart: dateformat(
        function(){
          const now = new Date();
          return new Date(now.getFullYear(), now.getMonth() - 2, now.getDate())
        }(), 'yyyy-mm-dd'
      ),
      searchAttachedEnd: '',
      searchDeviceId: '',
      searchAnalysisId: '',
      searchPatientInitialFirst: '',
      searchPatientInitialSecond: '',

      selectedAnalysisOption: '全て',
      selectSexList: ['全て', '男', '女'],
      searchSelectedSex: '全て',
      searchPatientAgeStart: '',
      searchPatientAgeEnd: '',
      searchExamNumber: '',

      isCollapse: true,
      centers: {},
      customerOrgs: {},
      params: {},
      patientIds: [],
      patientItem: null,
      currentStatus: null,
      selectedLinesPerPage: 10,
      linesPerPageList: [10, 30, 50],
      isUnusedOnly: false,
      isOverQueryLimit: false,
      msgsOverQueryLimit: '',
      sortBy: ['patient_attached'],
      sortDesc: [true],
    };
  },
  computed: {
    isNotImplementedActionPdf() {
      if ('is_not_implemented_action_pdf' in this.$store.state.configs && this.$store.state.configs.is_not_implemented_action_pdf == true) {
        return true;
      }
      return false;
    },
    selectAnalysisOptionList() {
      return ['全て'].concat(this.ANALYSIS_OPINION_TYPES)
    },
  },
  methods: {
    newPatientForm() {
      this.patientItem = null;
      this.patientMode = 'new';
      this.patientDialogModel = true;
      this.currentStatus = null;
    },
    updatePatientForm(item) {
      console.log(item);
      this.patientItem = item.patient;
      this.patientMode = 'edit';
      this.patientDialogModel = true;
      this.currentStatus = item.status;
    },
    async cancelPatientForm(item) {

      if (!this.isPossibleCancel(item.created)) {
        await this.$root.$alert('エラー', `登録してから1時間が経過しているためキャンセルできません。`);
        item.isPossibleCancel = false;
        return;
      }

      const result = await this.$root.$confirm('確認', `本当に、デバイスID = ${item.device_id}, 検査番号 = ${item.exam_number} の検査開始登録をキャンセルしますか？`)
      if (!result) return;
      try {
        await this.$db.runTransaction(async (transaction) => {
          // devices/{device_id}の取得
          const deviceDocRef = this.$db.doc('devices/' + item.device_id);
          const deviceDoc = await transaction.get(deviceDocRef);
          if (!deviceDoc.exists) throw new Error(`デバイスID = ${item.device_id}, 検査番号 = ${item.exam_number} のデータがありません。`);
          const device = deviceDoc.data();
           // 使用中状態でなければエラー
          if (device.status != 4) throw new Error('指定されたデバイスの状態が正しくありません。state = ' + device.status);
          // 患者Id、医療機関Idを取得しておく
          const patientId = device.patient_id;
          const orderOrgId = device.order_org_id;

          // 患者情報を取得
          const patientDocRef = this.$db.doc('patients/' + patientId);

          // 医療機関の在庫数の減算
          let orgDocRef = this.$db.doc('customer_orgs/' + orderOrgId);
          let orgDoc = await transaction.get(orgDocRef);
          let orgData = orgDoc.data();
          let holter_stock_quantity = orgData.holter_stock_quantity - 1;
          if (holter_stock_quantity < 0) holter_stock_quantity = 0;
          let updateInventoryData = {
            holter_stock_quantity,
            modified: this.$firebase.firestore.FieldValue.serverTimestamp()
          };

          // デバイス、患者情報を削除および在庫数の更新
          transaction = await transaction.delete(deviceDocRef);
          transaction = await transaction.delete(patientDocRef);
          transaction = await transaction.update(orgDocRef, updateInventoryData);
        });
        // 操作記録
        this.$functions.log({
          tags: ['patient', 'cancel'],
          message:  `${item.device_id}：検査番号=${item.exam_number}：患者情報の登録をキャンセルしました。`,
        });
      } catch (e) {
        await this.$root.$alert('エラー', `検査開始登録のキャンセルでエラーが発生しました。${e.message}`);
      }
    },
    onPatientDialogClosed(isUpdateFinished = false) {
      console.log('Analyses onPatientDialogClosed');
      if (this.patientMode == 'edit' && isUpdateFinished) {
        // 更新完了の場合は一覧を再取得
        this.subscribeItems();
      }
      this.patientItem = null;
      this.patientMode = 'none';
      this.patientDialogModel = false;
    },
    closeDialog() {
    },
    onDialogClosed() {
      // 削除完了後に呼び出されるCallback
      this.mode = 'none';
      this.currentItem = null;
    },
    itemNotDeleted() {
      // 削除完了後に呼び出されるCallback
      this.mode = 'none';
      // console.log('item-not-deleted');
    },
    /**
     * 一覧部の解析結果(アイコン)押下時の処理
     */
    async openMyHolter(item) {

      // 解析結果を表示する
      try {
        // 操作記録
        this.$functions.log({
          tags: ['analysis', 'redirect_result'],
          message: `${item.device_id}：解析ID=${item.analysis_id}：心電図の解析結果を開きます。`,
        });

        // 空白のページを新規タブで表示
        window.open('', '_blank');
      } catch (e) {
        console.error(e);
        await this.$root.$alert('エラー', `解析結果表示処理でエラーが発生しました。${e.message}`);
      }
    },

    async openOverviewPdf(item) {

      let confirmResult = await this.$root.$confirm(
        '確認',
        `外部サイトMy holter®️にアクセスして解析結果レポート概要ページ ( デバイスID = ${item.device_id}, 検査番号 = ${item.exam_number} ) を表示しますか？`,
        {width: 600, isMyholterRedirect: true}
      )
      if (!confirmResult) return;

      // functionsにリクエストを送る
      try {
        const result = await this.$root.$progressive(
          this.$functions.registerMyholterLoginRequest({
            analysis_id: item.analysis_id,
            device_id: item.device_id
          })
        );
        console.log(result);
        if (!('data' in result) || result.data.result != 'OK') {
          let errMsg = 'data' in result && 'errors' in result.data
            ? JSON.stringify(result.data.errors)
            : 'registerMyholterLoginRequest failed';
          throw new Error(errMsg);
        }
        const id = result.data.id;

        // 操作記録
        this.$functions.log({
          tags: ['analysis', 'redirect_result'],
          message: `${item.device_id}：解析ID=${item.analysis_id}：解析結果レポート概要ページを開きます。`,
        });

        // My holter®️にリダイレクトする
        const isNewMyholter = this.isNEwMyholter(item);
        const token = await this.$auth.currentUser.getIdToken(true);
        let viewReportUrl = isNewMyholter ? this.$store.state.configs.my_holter_url_2 : this.$store.state.configs.my_holter_url;
        if (viewReportUrl.slice(-1) != '/')  viewReportUrl = viewReportUrl + '/';
        viewReportUrl = viewReportUrl + (isNewMyholter ? 'patch-ecgs/view-overview-pdf2' : 'patch-ecgs/view-overview-pdf');
        window.open(viewReportUrl + `?id=${id}&token=${token}&analysis_id=${item.analysis_id}&device_id=${item.device_id}`, '_blank');
      } catch (e) {
        console.error(e);
        await this.$root.$alert('エラー', `解析結果レポート概要ページ表示処理でエラーが発生しました。${e.message}`);
      }
    },

    isNEwMyholter(item) {
      let isNewMyholter = false
      if (this.$store.state.configs.switching_date_of_myholter) {
        // マイホルター切り替え日が設定されている場合
        let switchingDateOfMyholter =  new Date(this.$store.state.configs.switching_date_of_myholter.seconds * 1000)
        // データアップロード日時を取得
        if (!item.device_uploaded) throw new Error('データアップロード日時がありません。');
        let deviceUploaded = new Date(item.device_uploaded.seconds * 1000)
        // データアップロード日が切り替え日時以降の場合はEG Holter解析システムへの接続
        if (switchingDateOfMyholter <= deviceUploaded) isNewMyholter = true;
      }
      return isNewMyholter;
    },

    subscribeItems() {

      // 未使用品表示チェックボックスを連打された時の対処用
      const beforeIsUnusedOnly = this.isUnusedOnly;

      itemsRef = this.$db.collection(`${this.itemsName}`);
      // 購読中は一旦解除した上で対応する。
      if (unsubscribeItems) unsubscribeItems();
      this.searchKeyword = this.searchKeyword ? this.searchKeyword.trim(): '';

      let cRef = itemsRef
        .where('deleted', '==', null)
        .where('type', '==', 'holter')
        .where('order_org_id', '==', this.$store.state.user.org_id);

      if (this.isUnusedOnly) {
        cRef = cRef.where('status', '==', 3);
      } else {
        // 未使用品のみでない場合、必須検索の日付指定検索
        cRef = cRef.where('status', 'in', [4, 5, 6, 7, 9, 11]);
        if (this.searchAttachedStart !== null && this.searchAttachedStart !== '') {
          let startAt = new Date(`${this.searchAttachedStart.replaceAll('-', '/')} 00:00:00`);
          cRef = cRef.where('patient_attached', ">=", startAt);
        }
        if (this.searchAttachedEnd !== null && this.searchAttachedEnd !== '') {
          let endAt = new Date(`${this.searchAttachedEnd.replaceAll('-', '/')} 23:59:59`);
          cRef = cRef.where('patient_attached', "<=", endAt);
        }
        cRef = cRef.orderBy('patient_attached', 'desc');
      }

      // 上限（上限に達したことを判定するために＋１件する）
      const queryLimit = this.$store.state.configs.query_limit_for_customer_analyses;
      this.msgsOverQueryLimit = [
        `抽出データ数が上限を超えました。上限を超えた分は表示されません。条件「検査開始日」の範囲を調整してください。`,
        '※ 拡張検索欄の条件では調整できません。'
      ];
      if (queryLimit > 0) cRef = cRef.limit(queryLimit + 1);  // 0以下の場合は無制限

      unsubscribeItems = cRef.onSnapshot((querySnapshot) => {
        // 最初および変更時の読み取り
        let listCount = 0;
        this.isOverQueryLimit = false;
        let items = [];

        // クエリ上限を超えた場合
        if (queryLimit > 0 && querySnapshot.docs.length > queryLimit) this.isOverQueryLimit = true;

        querySnapshot.forEach(async (doc) => {

          // クエリ件数が上限を超えた場合は終了
          listCount = listCount + 1;
          if (queryLimit > 0 && listCount > queryLimit) return;

          let data = doc.data();

          // 患者装着以降かつ紛失以外の場合に追加
          if (data.analyzed == null && data.status == 9) return;

          data.analyzed =  ts2str(data.analyzed);
          data.patient = '';
          data.patient_initial = '';
          data.exam_number = '';
          data.customer_staff_Names = '';
          data.doctors = '';
          data.age = null;
          data.sex = '';
          data.isPossibleCancel = false;
          data.created = null;

          data = await this.appendPatientData(data, doc.id);
          if (!data.display) return;

          if (!this.isHitExtendedSearchCondition(
              this.selectedAnalysisOption,
              this.searchDeviceId,
              this.searchAnalysisId,
              this.searchSelectedStatus,
              this.searchExamNumber,
              this.searchPatientInitialFirst,
              this.searchPatientInitialSecond,
              this.searchSelectedSex,
              this.searchPatientAgeStart,
              this.searchPatientAgeEnd,
              this.selectedCustomerStaffId,
              data
          )) return;

          // 未使用品表示チェックボックスが変えられているときは終了
          if (beforeIsUnusedOnly !== this.isUnusedOnly) return;

          items.push(data);
        });
        this.items = items;
      }, (e) => {
        console.log('error', e);
        this.items = [];
      });
    },
    isHitExtendedSearchCondition(
      selectedAnalysisOption,
      searchDeviceId,
      searchAnalysisId,
      searchSelectedStatus,
      searchExamNumber,
      searchPatientInitialFirst,
      searchPatientInitialSecond,
      searchSelectedSex,
      searchPatientAgeStart,
      searchPatientAgeEnd,
      selectedCustomerStaffId,
      deviceData
    ) {
      /**
       * スタッフ
       */
      if (selectedCustomerStaffId !== null) {
        let isExist = false;
        if (!deviceData.patient) return false;
        for (const staff of deviceData.patient.customerStaffs) {
          if (staff.id == selectedCustomerStaffId) {
            isExist = true;
            break;
          }
        }
        if (!isExist) return false;
      }
      /**
       * デバイスID
       */
      if (searchDeviceId !== null
        && searchDeviceId !== ''
        && deviceData.device_id.indexOf(searchDeviceId) === -1
      ) {
        return false;
      }
      /**
       * デバイス状態
       */
      if (searchSelectedStatus !== null) {
        if (searchSelectedStatus === 7) {
          // 「解析完了」が指定されている場合、解析完了日時が設定されていない場合は対象外
          if (!deviceData.analyzed) return false;
        } else if (searchSelectedStatus !== deviceData.status) {
          // その他の場合は、指定されたステータスと設定されているステータス番号が一致していなければ対象外
          return false;
        }
      }
      /**
       * 解析オプション
       */
      if (selectedAnalysisOption !== '全て') {
        if (!deviceData.patient) return false;
        const analysisOpinionType = this.getDisplayAnalysisOpinionTypeString(deviceData.patient, deviceData.status, this.$store.state.userOrg);
        if (analysisOpinionType !== selectedAnalysisOption) return false;
      }
      /**
       * 解析ID
       */
      const isInputedAnalysisId = (searchAnalysisId !== null && searchAnalysisId !== '');
      const isRegisteredAnalysisId = deviceData.analysis_id !== null;

      if (isInputedAnalysisId && !isRegisteredAnalysisId) {
        return false;
      }
      if (isInputedAnalysisId
        && isRegisteredAnalysisId
        && deviceData.analysis_id.toString().indexOf(searchAnalysisId) === -1
      ) {
        return false;
      }
      /**
       * 検査番号
       */
      const isInputedExamNumber = (searchExamNumber !== null && searchExamNumber !== '');
      if (isInputedExamNumber
        && deviceData.exam_number.indexOf(searchExamNumber) === -1
      ) {
        return false;
      }
      /**
       * イニシャル
       * 大文字・小文字は区別しないため、小文字に統一した状態でチェックする
       */
      const initialFull = (`${searchPatientInitialFirst} ${searchPatientInitialSecond}`).toLowerCase();
      const isInputedInitial = initialFull !== ' ';
      if (isInputedInitial
        && deviceData.patient_initial.toLowerCase().indexOf(initialFull) === -1
      ) {
        return false;
      }
      /**
       * 性別
       */
      if (searchSelectedSex !== '全て'
        && searchSelectedSex !== deviceData.sex
      ) {
        return false;
      }
      /**
       * 年齢
       */
      const isInputedPatientAgeStart = searchPatientAgeStart !== '';
      const isInputedPatientAgeEnd = searchPatientAgeEnd !== '';
      const isRegisteredPatientAge = deviceData.age !== null;

      if ((isInputedPatientAgeStart || isInputedPatientAgeEnd)
        && deviceData.age == null
      ) {
        return false;
      }
      if (isInputedPatientAgeStart
        && isRegisteredPatientAge
        && searchPatientAgeStart > deviceData.age
      ) {
        return false;
      }
      if (isInputedPatientAgeEnd
        && isRegisteredPatientAge
        && searchPatientAgeEnd < deviceData.age
      ) {
        return false;
      }

      return true;
    },
    async appendPatientData(data, id) {
      data.display = true;
      // 患者IDが未登録の場合は付加するものが無いため、そのまま返却する
      if (!data.patient_id) return data;

      const {patient, exists} = await this.findPatient(data.patient_id);

      if (!exists) {
        data.display = false;
        return data;
      }

      const customerStaffs = await this.getCustomerStaffsByIdList(patient.customerStaffIdList);

      // 編集用の患者情報
      data.patient = {
        deviceId: id.replace('T-', ''),
        customerStaffs: customerStaffs,
        doctors: patient.doctors,
        age: patient.age,
        sex: patient.sex,
        patientInitial: patient.patient_initial,
        remarks: patient.remarks,
        examNumber: patient.exam_number,
        patientAttached: new Date(data.patient_attached.seconds * 1000),
        analysis_opinion_type: patient.analysis_opinion_type,
        anarysis_opinion_type_change_history: patient.anarysis_opinion_type_change_history,
      }
      // テーブル表示用の患者情報
      data.age = patient.age;
      data.patient_initial = patient.patient_initial;
      data.patient_attached = ts2str(data.patient_attached);
      data.exam_number = patient.exam_number;
      let customerStaffNamesString = '';
      customerStaffs.forEach((staff, index) => {
        if (index > 0) {
          customerStaffNamesString += '\r\n'
        }
        customerStaffNamesString += staff.name;
      });
      data.customer_staff_Names = customerStaffNamesString;
      data.exam_number = patient.exam_number;
      let sexName = '';
      if (patient.sex == 1) {
        sexName = '男';
      } else if (patient.sex == 2) {
        sexName = '女';
      }
      data.sex = sexName;
      data.analysis_opinion_type = patient.analysis_opinion_type;
      data.anarysis_opinion_type_change_history = patient.anarysis_opinion_type_change_history;
      data.isPossibleCancel = this.isPossibleCancel(patient.created);
      data.created = patient.created;
      data.display = true;
      return data;
    },
    subscribePatients() {

    },
    subscribeCustomerStaffs() {
      if (unsubscribeCustomerStaffs) unsubscribeCustomerStaffs();
      unsubscribeCustomerStaffs = this.$db.collection('customer_staffs')
        .where('org_id', '==', this.$store.state.user.org_id)
        .onSnapshot((querySnapshot) => {
          this.customerStaffSelectItems = [{ text: '全て', value: null }];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            this.customerStaffSelectItems.push({
              text: data.name,
              value: doc.id
            });
          });
      });
    },
    async findPatient(patientId) {
      let patientsRef = this.$db.doc(`patients/${patientId}`);
      try {
        // tryは、存在しないpatientの場合はエラーとなるのでその対処
        // 通常はあり得ない、主に開発環境で発生
        const patientDoc = await patientsRef.get();
        if (!patientDoc.exists) return {patient:{}, exists: false};
        let patientData = patientDoc.data();
        if (!patientData.customer_staff_id_list) {
          patientData.customer_staff_id_list = [];
        }
        return {
          patient: {
            patient_initial: patientData.patient_initial,
            doctors: patientData.doctors,
            exam_number: patientData.exam_number,
            age: patientData.age,
            sex: patientData.sex,
            remarks: patientData.remarks,
            customerStaffIdList: patientData.customer_staff_id_list,
            created: patientData.created,
            analysis_opinion_type: patientData.analysis_opinion_type,
            anarysis_opinion_type_change_history: patientData.anarysis_opinion_type_change_history,
          },
          exists: true
        }
      } catch(e) {
        console.warn(patientId, e);
      }
      return {patient:{}, exists: false};
    },
    async getCustomerStaffsByIdList(customerStaffIdList) {
      if (customerStaffIdList.length < 1) {
        return [];
      }
      const staffs = [];
      let staffsRef = this.$db.collection('customer_staffs');
      for (const id of customerStaffIdList) {
        try {
          // tryは、物理的に削除されたスタッフのIDを指定するとエラーとなることへの対処
          // Firestore rulesの影響
          // 実際の運用ではソフトデリーションなので発生しない想定（主に開発環境で発生）
          const staffDoc = await staffsRef.doc(id).get();
          if (!staffDoc.exists) continue;
          const staffData = staffDoc.data();
          staffs.push({
            id: staffDoc.id,
            name: staffData.name,
          });
        } catch(e) {
          console.warn(id, e);
        }
      }
      return staffs;
    },
    async afterSnapshotted() {
      // oepnコマンドの場合、対象の解析を開くかどうかのダイアログを表示する
      if (this.$route.params) {
        this.params = this.$route.params;
        if (this.params && this.params.cmd == 'open') {
          let deviceData = null;
          for (let i = 0; i < this.items.length; i++) {
            if (this.items[i].device_id == this.params.device_id) {
              deviceData = this.items[i];
              break;
            }
          }
          if (deviceData) {
            this.openMyHolter(deviceData);

          }
        }
      }
    },
    isSwitchingUser() {
      // superUserが存在しない場合は、なりすまし不可
      if (!this.$store.state.superUser) {
        return false;
      }
      // ログイン中のユーザーIDと、superUserのIDが同一の場合はなりすましではない
      if (this.$store.state.user.id == this.$store.state.superUser.id) {
        return false;
      }
      return true;
    },
    isPossibleCancel(created) {
      return (new Date()).getTime() < created.toMillis() + 1000 * 3600;
    },
    toggleCollapse() {
      this.isCollapse = !this.isCollapse;
    },
    displayAnalysisOpinionType(item) {
      if (!('patient' in item) || !item.patient) return '';
      const orgData = this.$store.state.userOrg;
      if (!orgData) return '';
      return this.getDisplayAnalysisOpinionTypeString(item.patient, item.status, orgData);
    },
    updateSortBy(v) {
      this.sortBy = v;
    },
    updateSortDesc(v) {
      this.sortDesc = v;
    },
    ecgType2Text(v) {
      if (/^T-[0-9]{6}$/.test(v.device_id)) {
        return this.$store.state.ecgTypes[1];
      }

      if (/^T-[A-Z][0-9]{6}$/.test(v.device_id)) {
        return this.$store.state.ecgTypes[2];
      }
      
      return '';
    },
  },
  mounted() {
    this.subscribeCustomerStaffs();

    // テーブル表示件数の初期読み込み
    if (this.$store.state.user.table_lines_par_page) {
      this.selectedLinesPerPage = this.$store.state.user.table_lines_par_page;
    }
    // 他の画面で表示件数が変更された場合、こちらの画面にも反映させる
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'setUserTableLinesParPage') {
        this.selectedLinesPerPage = state.user.table_lines_par_page;
      }
    });

    this.subscribeItems();
    this.centers = this.$store.state.centersDict;
    this.customerOrgs = this.$store.state.customerOrgsDict;

    if (this.$store.state.user.role.startsWith('customer')) {
      if (this.isNotImplementedActionPdf) {
        this.headers = this.headersCustomerTmp;
      } else {
        this.headers = this.headersCustomer;
      }
    }
  },
  watch: {
    selectedLinesPerPage(lineNumber) {
      if (this.$store.state.user.table_lines_par_page != lineNumber) {
        const customerStaffsRef = this.$db
          .collection('customer_staffs')
          .doc(this.$store.state.user.id);
        // 運営主体管理ユーザーによる、なりすましの場合は更新しない
        if (!this.isSwitchingUser()) {
          customerStaffsRef.update({
            table_lines_par_page: lineNumber
          });
        }
        this.$store.commit('setUserTableLinesParPage', lineNumber);
      }
    },
    patientAttachedStart(value) {
      console.log(value);
    },
    searchAttachedStart(date) {
      if (date) {
        date = new Date(date + ' 00:00:00');
        if (date.toString() === "Invalid Date") return;
      }
      this.subscribeItems();
    },
    searchAttachedEnd(date) {
      if (date) {
        date = new Date(date + ' 23:59:59');
        if (date.toString() === "Invalid Date") return;
      }
      this.subscribeItems();
    },
    isUnusedOnly() {
      this.selectedAnalysisOption = '全て';
      this.searchDeviceId = '';
      this.searchAnalysisId = '';
      this.searchSelectedStatus = null;
      this.searchExamNumber = '';
      this.searchPatientInitialFirst = '';
      this.searchPatientInitialSecond = '';
      this.searchSelectedSex = '全て';
      this.searchPatientAgeStart = '',
      this.searchPatientAgeEnd = '',
      this.selectedCustomerStaffId = null;
    },
  },
  beforeDestroy() {
    // 変更監視を停止
    if (unsubscribeItems) unsubscribeItems();
    if (unsubscribeCustomerStaffs) unsubscribeCustomerStaffs();
  }
}
</script>

<style scoped>
  .theme-color {
    color: white;
    background-color:  #B3131A;
  }

  .top-rounded {
    margin:0px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .border-less {
    border: none !important;
  }

  .patient-initial {
    display: flex;
    position: relative;
  }

  .patient-initial .one-digit-charactor {
    flex: 0 1 1rem;
    margin-right: 1rem;
  }

  .patient-initial .v-text-field__details {
    position: absolute;
    white-space: nowrap;
    overflow: visible;
  }

  .patient-age {
    display: flex;
    position: relative;
  }

  .patient-age .three-digit-charactor {
    flex: 0 1 3rem;
    margin-right: 1rem;
  }

  header#search-field {
    height: 302px !important;
    transition-property: height, max-width, width;
    transition-duration: 0.25s, 0.25s, 0.25s;
    padding :12px 12px 0 12px;
  }

  header#search-field.v-toolbar--collapsed {
    height: 70px !important;
  }
</style>

<style>
  .patient-initial .first-char .v-text-field__details {
    top: 4.3rem;
  }

  .patient-initial .second-char .v-text-field__details {
    top: 3.3rem;
    left: 11.7rem;
  }

  .patient-age .v-text-field__details {
    position: absolute;
    white-space: nowrap;
    overflow: visible;
  }

  header#search-field .v-toolbar__content {
    flex-flow: column;
    align-items: baseline;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    background-color: white;
    border-radius: 5px;
    margin: 0;
  }
</style>