// 解析オプション
const ANALYSIS_OPINION_TYPES = [
  '自動解析',
  '技師解析',
  '医師所見'
];


export default {
  data() {
    return {
    }
  },
  computed: {
    ANALYSIS_OPINION_TYPES() {
      return ANALYSIS_OPINION_TYPES
    },
    isTechCp2207() {
      return 'is_tech_cp_2207' in this.$store.state.configs && this.$store.state.configs.is_tech_cp_2207 === true ? true : false;
    },
  },
  methods: {
    // 状態や契約状況から画面に表示する解析オプションを判定し、オプション名を返す
    getDisplayAnalysisOpinionTypeString(patientData, status, orgData) {
      if (!orgData) return ''
      const index = this.getDisplayAnalysisOpinionTypeIndex(patientData, status, orgData);
      const string = this.ANALYSIS_OPINION_TYPES[index];
      if (!string) return '（該当無し）';
      return string;
    },
    // 状態や契約状況から画面に表示する解析オプションを判定し、インデックスを返す
    getDisplayAnalysisOpinionTypeIndex(patientData, status, orgData) {

      // オプション解析契約状況
      const isContractedAnalysisOption
        = 'is_contracted_analysis_option' in orgData && orgData.is_contracted_analysis_option === true ? true : false;

      // 設定されている解析オプション
      let analysisOpinionType
        = 'analysis_opinion_type' in patientData && patientData.analysis_opinion_type ? patientData.analysis_opinion_type : null;

      // 状態で調整
      if (status == 4 || status == 5) {
        // 患者装着済み、又は受付センター着荷状態
        if (!isContractedAnalysisOption || analysisOpinionType === null) {
          // オプション解析未契約、又は解析オプション未設定（旧データ）の場合
          analysisOpinionType = 0;

          // 22/07 技師解析CPに対する一時的な対応
          if (!isContractedAnalysisOption && this.isTechCp2207) analysisOpinionType = 1;
        }
      } else if (status >= 6 && status != 8) {
        // 解析依頼後＆未使用期限切れ以外
        if (analysisOpinionType === null) {
          // 解析オプション未設定（旧データ）の場合
          analysisOpinionType = 0;
        }
      }

      return parseInt(analysisOpinionType);
    },
  },
}